<template>
  <OrganismsNavbar
    v-model="subCategory"
    @on-hover="subCategory = true"
    @on-leave="subCategory = false"
  />
  <AtomsOverlay v-model="subCategory" />
  <div class="kg-content container">
    <slot />
  </div>
  <OrganismsFooter />
</template>

<script setup lang="ts">
const subCategory = useSubCategory()
</script>
